<template>
	<main @theevent="handleTheEvent" class="home">
		<section class="countdown">
			<!-- <h5 class="content-wrapper mt-4 mb-3">
				All event tickets go on sale in:
			</h5> -->
			<client-only>
				<countdown :time="time" :transform="transform">
					<template slot-scope="props">
						<div class="flex justify-between content-wrapper mt-4">
							<h2>{{ props.days }}</h2>
							<h2>·</h2>
							<h2>{{ props.hours }}</h2>
							<h2>·</h2>
							<h2>{{ props.minutes }}</h2>
							<h2>·</h2>
							<h2>{{ props.seconds }}</h2>
						</div>
					</template>
				</countdown>
			</client-only>
		</section>
		<section class="page-detail">
			<div class="content-wrapper flex items-center justify-between">
				<h5>days</h5>
				<h5>·</h5>
				<h5>hours</h5>
				<h5>·</h5>
				<h5 class="de:hidden">mins</h5>
				<h5 class="hidden de:block">minutes</h5>
				<h5>·</h5>
				<h5 class="de:hidden">secs</h5>
				<h5 class="hidden de:block">seconds</h5>
			</div>
		</section>
		<section class="page-content">
			<div class="content-wrapper py-5 de:py-40 flex justify-around">
				<div class="flex items-center flex-col">
					<h3 class="text-center">
						An immersive, five-day cocktail journey through
						Melbourne's bars, restaurants and spaces, with the
						industry's best and brightest.
					</h3>
					<h3 class="mb-5 mt-8 de:mt-16 de:mb-5 text-center">
						Early-bird tickets <br class="de:hidden" />on sale now!
					</h3>
					<div class="flex flex-col de:flex-row mb-6">
						<nuxt-link
							to="/bar-safari"
							class="btn de:btn--large mb-5 de:mb-0 de:mr-20"
							>Bar Safari ></nuxt-link
						>
						<nuxt-link to="/events" class="btn de:btn--large"
							>Events ></nuxt-link
						>
					</div>
				</div>
			</div>
		</section>
		<Partners></Partners>
	</main>
</template>

<script>
import { mapGetters } from 'vuex'
// import Newsletter from '~/components/Newsletter.vue'
import Partners from '~/components/Partners.vue'

export default {
	computed: {
		...mapGetters(['showNewsletter']),
		time: () => {
			const now = new Date()
			const launch = new Date(2020, 1, 26)

			return launch - now // + 12 * 3600 * 1000
		}
	},
	data: () => {
		return {}
	},
	components: { Partners },
	methods: {
		transform(props) {
			Object.entries(props).forEach(([key, value]) => {
				const digits = value < 10 ? `0${value}` : value
				props[key] = `${digits}`
			})

			return props
		},
		toggleNewsletter() {
			this.$store.dispatch('toggleNewsletter')
		},
		handleTheEvent() {
			console.log('handling the event')
		}
	}
}
</script>
<style lang="scss" scoped>
@import 'index.scss';
</style>
